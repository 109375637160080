import React from 'react';
import _ from 'lodash';
import { navigate } from 'gatsby';
import Dotdotdot from 'react-dotdotdot';
import { Carousel } from 'react-bootstrap';
import testimonials from '../../../../data/homeTestimonials';

import styles from './testimonials.module.scss';
import carouselStyles from '../CarouselJumbotron/carouselJumbotron.module.scss';

const Testimonials = () => {
  const quoteLeft = <div className={styles.quoteLeft}>&ldquo;</div>;
  const quoteRight = <div className={styles.quoteRight}>&rdquo;</div>;

  const testimonialButton = (
    <div className={styles.buttonContainer}>
      <div
        className={styles.whiteButton}
        onClick={() => navigate('/testimonials')}
        role="presentation"
      >
        TESTIMONIALS
      </div>
    </div>
  );

  const carouselItems = _.map(testimonials, (testimonial, index) => {
    return (
      <Carousel.Item key={index}>
        <div className={styles.slide} />
        <Carousel.Caption className={styles.paddingAdjust}>
          <div className={styles.slideContentWrapper}>
            <Dotdotdot clamp={5} useNativeClamp>
              <p className={carouselStyles.testimonialText}>{testimonial}</p>
            </Dotdotdot>
            {testimonialButton}
            {quoteLeft}
            {quoteRight}
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    );
  });

  return (
    <div style={{ position: 'relative' }}>
      <Carousel controls={false} indicators={false} pauseOnHover={false} interval={10000}>
        {carouselItems}
      </Carousel>
      <div className={styles.dividerTriangle} />
    </div>
  );
};

export default Testimonials;
