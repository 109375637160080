import React from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { FaPhone } from 'react-icons/fa';

import styles from './philosophy.module.scss';

const Philosophy = () => (
  <StaticQuery
    query={graphql`
      query {
        butterfly: file(relativePath: { eq: "logos/butterflyLogo.png" }) {
          childImageSharp {
            fluid(maxWidth: 270) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
      }
    `}
    render={({ butterfly }) => {
      const phoneIcon = <FaPhone size={22} color="#FFF" />;
      const ActionCard = ({ lastStop }) => {
        return (
          <a href="tel:8014271054">
            <div className={styles.actionCard}>
              <div className={styles.cardIcon}>{phoneIcon}</div>
              {lastStop && (
                <p className={styles.actionLastStop}>
                  This is your last stop. You&apos;ve finally found OCD treatment providers who
                  get&nbsp;you!!
                </p>
              )}
              <p className={styles.actionCopy}>Schedule your appointment!</p>
              <p className={styles.actionPhone}>801-427-1054</p>
            </div>
          </a>
        );
      };

      return (
        <section className={styles.philosophyContainer}>
          <div className={styles.textContainer}>
            <p className={styles.philosophyCopy}>
              {/* We specilaize in treating anxiety, OCD, and OC related disorders. */}
              We have{' '}<strong>30+ years of experience</strong> successfully treating{' '}
              <strong>anxiety, OCD, and OC related disorders</strong>. We are NOT general
              practitioners. <strong>We are experts and we&nbsp;get&nbsp;results!</strong>
            </p>
          </div>

          <ActionCard />

          <div className={styles.textContainer}>
            <p className={styles.philosophyCopy}>
              <strong>We understand your pain and we can help!</strong> We have been trained to
              treat anxiety, OCD, and OC related disorders by renowned experts in the field. We
              provide anxiety, and OCD consultation and training for other psychotherapists.
            </p>
          </div>

          <div
            className={styles.button}
            onClick={() => navigate('/philosophy')}
            role="presentation"
          >
            LEARN MORE
          </div>

          {/* <div className={styles.backgroundLogo}>
            <Img fluid={butterfly.childImageSharp.fluid} alt="Mindset Family Therapy" />
          </div> */}
        </section>
      );
    }}
  />
);

export default Philosophy;
