import _ from 'lodash';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Post from './Post';
import { cleanExcerpt, cleanTitle, getFeaturedImage } from '../../../../utils/postUtils';

import styles from './recentPosts.module.scss';

const RecentPosts = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allAllPostsJson(sort: { fields: [date], order: DESC }, limit: 3) {
            edges {
              node {
                id
                slug
                title
                content
                date
                fields {
                  localFeaturedImage {
                    childImageSharp {
                      fluid(maxWidth: 300, maxHeight: 300) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const posts = data.allAllPostsJson.edges;
        const recentPosts = _.map(posts, ({ node }) => {
          const { id, slug, title, content, fields } = node;

          const featuredImage = _.get(fields, 'localFeaturedImage.childImageSharp.fluid', null);
          let backupImage = getFeaturedImage(content);

          if (_.isNil(featuredImage) || _.isNil(backupImage)) {
            backupImage =
              'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497110189/flowers_b9l2ed.jpg';
          }

          return (
            <Post
              key={id}
              id={id}
              title={cleanTitle(title)}
              image={featuredImage}
              backupImage={backupImage}
              excerpt={cleanExcerpt(content)}
              slug={slug}
            />
          );
        });
        return (
          <div className={styles.recentPostsMain}>
            <h2 className={styles.title}>Recent Blog Posts</h2>
            <div className={styles.posts}>{recentPosts}</div>
          </div>
        );
      }}
    />
  );
};

export default RecentPosts;
