const cardInfo = {
  adolescents:
    "Together, we can build and strengthen your adolescent's confidence, resilience, self-compassion, and vision for their future, regardless of their current mental health challenges.",
  anxietyBook:
    'Change the way you view and respond to anxiety, uncertainty, and self-doubt. Cultivate more self-compassion.',
  imperfectlyGoodBook:
    'Break free from the doubt and depression caused by moral and religious anxiety.',
  mindsetBook:
    'Bring out the best in yourself as a parent so you can empower your kids to be confident, kind, and resilient.',
  ocd:
    'Treatment is mapped according to your individual needs, with an integrative model that fuses traditional CBT and ERP with ACT (Acceptance and Commitment Therapy).',
  relationships:
    'Each couple comes to therapy with a unique set of challenges. Throughout the therapeutic process, you and your partner will be taught skills that can enhance your relationship.',
  children:
    'Help' +
    ' your child develop responsibility and self-control while enhancing your' +
    ' relationship' +
    " with your child. Learn to better understand your child's emotional needs.",
  scrupulosity:
    'We understand scrupulosity (religious and moral OCD) and the\n' +
    'nuances and sensitivities that\n' +
    'come with a religious culture. Learn\n' +
    'how to get back to enjoying your\n' +
    '\n' +
    'religion and life!',
  trauma:
    'Have you ever been in a situation where you felt terrified, helpless, powerless, or unsafe? We would feel' +
    ' honored to help you in your journey and be part of your healing process.',
  other:
    'Whether it be depression, trauma, other anxiety disorders, or a host of other challenges — we have the expertise to deliver the personalized help you deserve.',
};

export default cardInfo;
