import React from 'react';
import CarouselJumbotron from './components/CarouselJumbotron';
// import ExperienceBanner from './components/ExperienceBanner';
import Philosophy from './components/Philosophy';
import Cards from './components/Cards';
import SendMessage from './components/SendMessage';
import RecentPosts from './components/RecentPosts';
import Testimonials from './components/Testimonials';

const Home = () => (
  <main>
    <CarouselJumbotron />
    {/*<ExperienceBanner />*/}
    <Philosophy />
    <Cards />
    <SendMessage />
    <RecentPosts />
    <Testimonials />
  </main>
);

export default Home;
