import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Carousel } from 'react-bootstrap';

import styles from './carouselJumbotron.module.scss';

const CarouselJumbotron = () => {
  // const [showControls, setShowControls] = useState(false);

  // const handleMouseEnter = () => {
  //   setShowControls(true);
  // };

  // const handleMouseLeave = () => {
  //   setShowControls(false);
  // };

  return (
    <StaticQuery
      query={graphql`
        query {
          backgroundLanding: file(relativePath: { eq: "jumbotron/background_landing.png" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      `}
      render={data => {
        const { backgroundLanding } = data;

        return (
          <div style={{ height: '50vh', minHeight: 400 }}>
            <Carousel
              slide={false}
              controls={false}
              indicators={false}
              pauseOnHover={false}
              interval={8500}
            >
              {/* new -- */}
              <Carousel.Item>
                <div className={styles.imageSlide}>
                  <Img
                    fluid={backgroundLanding.childImageSharp.fluid}
                    style={{ height: '50vh', minHeight: 400, opacity: 0.8 }}
                  />
                </div>
                <Carousel.Caption>
                  <div className={styles.textContainerBookSlide}>
                    <h1>Why Mindset Family Therapy?</h1>
                    <h3 className={styles.subText}>
                      Specialized.
                      <br />
                      Experienced.
                      <br />
                      Results.
                    </h3>
                  </div>
                  {/* <div className={styles.buttonContainer}>
                    <div
                      className={styles.singleButton}
                      onClick={() => navigate('/books/let-go-of-anxiety')}
                      role="presentation"
                    >
                      LEARN MORE
                    </div>
                  </div> */}
                </Carousel.Caption>
              </Carousel.Item>

              {/* Imperfectly Good -- BOOK */}
              {/* <Carousel.Item>
                <div className={styles.imageSlide}>
                  <Img
                    fluid={imperfectlyGoodImageData.childImageSharp.fluid}
                    style={{ height: '70vh', minHeight: 400 }}
                  />
                </div>
                <Carousel.Caption>
                  <div className={styles.textContainerBookSlide}>
                    <h1>Is Scrupulosity OCD hijacking your&nbsp;faith?</h1>
                    <h3 className={styles.subText}>
                      Check out Annabella Hagen{`'`}s new&nbsp;book:
                      <br />
                      <div className={styles.bookTitle}>
                        <i>
                          Imperfectly Good: Navigating Religious and Moral Anxiety to Release Fear
                          and Find&nbsp;Peace
                        </i>
                      </div>
                    </h3>
                  </div>
                  <div className={styles.buttonContainer}>
                    <div
                      className={styles.singleButton}
                      onClick={() => navigate('/books/imperfectly-good')}
                      role="presentation"
                    >
                      LEARN MORE
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item> */}

              {/* Adolescents */}
              {/* <Carousel.Item>
                <div className={styles.imageSlide}>
                  <Img
                    fluid={adolescentsImageData.childImageSharp.fluid}
                    style={{ height: '70vh', minHeight: 400 }}
                  />
                </div>
                <Carousel.Caption>
                  <div className={styles.textContainerSurf}>
                    <h1>Is your teenager struggling?</h1>
                  </div>
                  <div className={styles.buttonContainer}>
                    <div
                      className={styles.whiteButton}
                      onClick={() => navigate('/relationships/adolescents')}
                      role="presentation"
                    >
                      LEARN MORE
                    </div>
                    <div
                      className={styles.orangeButton}
                      onClick={() => navigate('/contact')}
                      role="presentation"
                    >
                      CONTACT US
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item> */}

              {/* Overview */}
              {/* <Carousel.Item>
                <div className={styles.imageSlide}>
                  <Img
                    fluid={surferImageData.childImageSharp.fluid}
                    style={{ height: '70vh', minHeight: 400 }}
                  />
                </div>
                <Carousel.Caption>
                  <div className={styles.textContainerSurf}>
                    <h1>Why Mindset Family&nbsp;Therapy?</h1>
                  </div>
                  <div className={styles.buttonContainer}>
                    <div
                      className={styles.singleButton}
                      onClick={() => navigate('/philosophy')}
                      role="presentation"
                    >
                      OUR APPROACH
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item> */}

              {/* Online Psychotherapy */}
              {/*<Carousel.Item>*/}
              {/*  <div className={styles.imageSlide}>*/}
              {/*    <Img*/}
              {/*      fluid={rocksImageData.childImageSharp.fluid}*/}
              {/*      style={{ height: '70vh', minHeight: 400 }}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <Carousel.Caption>*/}
              {/*    <div className={styles.textContainerRocks}>*/}
              {/*      <h1>Online Therapy</h1>*/}
              {/*      <h3 className={styles.subText}>*/}
              {/*        (If you live in Utah or outside the&nbsp;United&nbsp;States)*/}
              {/*      </h3>*/}
              {/*    </div>*/}
              {/*    <div className={styles.buttonContainer}>*/}
              {/*      <div*/}
              {/*        className={styles.whiteButton}*/}
              {/*        onClick={() => navigate('/online-psychotherapy')}*/}
              {/*        role="presentation"*/}
              {/*      >*/}
              {/*        LEARN MORE*/}
              {/*      </div>*/}
              {/*      <div*/}
              {/*        className={styles.orangeButton}*/}
              {/*        onClick={() => navigate('/contact')}*/}
              {/*        role="presentation"*/}
              {/*      >*/}
              {/*        CONTACT US*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </Carousel.Caption>*/}
              {/*</Carousel.Item>*/}

              {/* Child-Parent Relationship */}
              {/*<Carousel.Item>*/}
              {/*  <div className={styles.imageSlide}>*/}
              {/*    <Img*/}
              {/*      fluid={familyImageData.childImageSharp.fluid}*/}
              {/*      style={{ height: '70vh', minHeight: 400 }}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <Carousel.Caption>*/}
              {/*    <div className={styles.textContainerRocks}>*/}
              {/*      <h1>Are you struggling with your&nbsp;child?</h1>*/}
              {/*    </div>*/}
              {/*    <div className={styles.buttonContainer}>*/}
              {/*      <div*/}
              {/*        className={styles.whiteButton}*/}
              {/*        onClick={() => navigate('/relationships/child-parent-relationship')}*/}
              {/*        role="presentation"*/}
              {/*      >*/}
              {/*        LEARN MORE*/}
              {/*      </div>*/}
              {/*      <div*/}
              {/*        className={styles.orangeButton}*/}
              {/*        onClick={() => navigate('/contact')}*/}
              {/*        role="presentation"*/}
              {/*      >*/}
              {/*        CONTACT US*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </Carousel.Caption>*/}
              {/*</Carousel.Item>*/}

              {/* Anxiety */}
              {/* <Carousel.Item>
                <div className={styles.imageSlide}>
                  <Img
                    fluid={mountainsCloudsImageData.childImageSharp.fluid}
                    style={{ height: '70vh', minHeight: 400 }}
                  />
                </div>
                <Carousel.Caption>
                  <div className={styles.textContainerPurpleClouds}>
                    <h1>
                      Is anxiety getting in the way of living the life you want&nbsp;to&nbsp;live?
                    </h1>
                  </div>
                  <div className={styles.buttonContainer}>
                    <div
                      className={styles.whiteButton}
                      onClick={() => navigate('/specialties/anxiety-treatment')}
                      role="presentation"
                    >
                      LEARN MORE
                    </div>
                    <div
                      className={styles.orangeButton}
                      onClick={() => navigate('/contact')}
                      role="presentation"
                    >
                      CONTACT US
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item> */}

              {/* GROUPS / OCD */}
              {/*<Carousel.Item>*/}
              {/*  <div className={styles.imageSlide}>*/}
              {/*    <Img*/}
              {/*      fluid={mountainImageData.childImageSharp.fluid}*/}
              {/*      style={{ height: '70vh', minHeight: 400 }}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <Carousel.Caption>*/}
              {/*    <div className={styles.textContainerMountain}>*/}
              {/*      <h1>Exposure and Response Prevention Group for&nbsp;OCD.</h1>*/}
              {/*      <h1>Scrupulosity OCD Education & Support&nbsp;Group.</h1>*/}
              {/*    </div>*/}
              {/*    <div className={styles.buttonContainer}>*/}
              {/*      <div*/}
              {/*        className={styles.whiteButton}*/}
              {/*        onClick={() => navigate('/groups')}*/}
              {/*        role="presentation"*/}
              {/*      >*/}
              {/*        LEARN MORE*/}
              {/*      </div>*/}
              {/*      <div*/}
              {/*        className={styles.orangeButton}*/}
              {/*        onClick={() => navigate('/contact')}*/}
              {/*        role="presentation"*/}
              {/*      >*/}
              {/*        CONTACT US*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </Carousel.Caption>*/}
              {/*</Carousel.Item>*/}

              {/* Perfectionism */}
              {/* <Carousel.Item>
                <div className={styles.imageSlide}>
                  <Img
                    fluid={purpleCloudsImageData.childImageSharp.fluid}
                    style={{ height: '70vh', minHeight: 400 }}
                  />
                </div>
                <Carousel.Caption>
                  <div className={styles.textContainerPurpleClouds}>
                    <h1>
                      Is the urge to be perfect affecting your life? Learn to be your
                      best&nbsp;self.
                    </h1>
                  </div>
                  <div className={styles.buttonContainer}>
                    <div
                      className={styles.whiteButton}
                      onClick={() => navigate('/specialties/perfectionism')}
                      role="presentation"
                    >
                      LEARN MORE
                    </div>
                    <div
                      className={styles.orangeButton}
                      onClick={() => navigate('/contact')}
                      role="presentation"
                    >
                      CONTACT US
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item> */}

              {/* Relationships */}
              {/*<Carousel.Item>*/}
              {/*  <div className={styles.imageSlide}>*/}
              {/*    <Img*/}
              {/*      fluid={coupleImageData.childImageSharp.fluid}*/}
              {/*      style={{ height: '70vh', minHeight: 400 }}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <Carousel.Caption>*/}
              {/*    <div className={styles.textContainerCouple}>*/}
              {/*      <h1>Couple Counseling</h1>*/}
              {/*    </div>*/}
              {/*    <div className={styles.buttonContainer}>*/}
              {/*      <div*/}
              {/*        className={styles.whiteButton}*/}
              {/*        onClick={() => navigate('/relationships/couple-counseling')}*/}
              {/*        role="presentation"*/}
              {/*      >*/}
              {/*        LEARN MORE*/}
              {/*      </div>*/}
              {/*      <div*/}
              {/*        className={styles.orangeButton}*/}
              {/*        onClick={() => navigate('/contact')}*/}
              {/*        role="presentation"*/}
              {/*      >*/}
              {/*        CONTACT US*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </Carousel.Caption>*/}
              {/*</Carousel.Item>*/}
            </Carousel>
          </div>
        );
      }}
    />
  );
};

export default CarouselJumbotron;
