import React from 'react';
import { FaMapSigns, FaHeart, FaChild, FaBrain, FaBook, FaPhone } from 'react-icons/fa';
import IndividualCard from './IndividualCard';
import cardInfo from '../../../../data/cardInfo';

import styles from './cards.module.scss';

const Cards = () => {
  const white = '#FFF';
  const signs = <FaMapSigns size={28} color={white} />;
  const heart = <FaHeart size={26} color={white} />;
  const child = <FaChild size={30} color={white} />;
  const brain = <FaBrain size={28} color={white} />;
  const book = <FaBook size={28} color={white} />;
  const phoneIcon = <FaPhone size={22} color="#FFF" />;

  const ActionCard = ({ lastStop }) => {
    return (
      <a href="tel:8014271054">
        <div className={styles.actionCard}>
          <div className={styles.cardIcon}>{phoneIcon}</div>
          {lastStop && (
            <p className={styles.actionLastStop}>
              This is your last stop. You&apos;ve finally found OCD treatment providers who
              get&nbsp;you!
            </p>
          )}
          <p className={styles.actionCopy}>Schedule your appointment!</p>
          <p className={styles.actionPhone}>801-427-1054</p>
        </div>
      </a>
    );
  };

  return (
    <section className={styles.cardsMain}>
      <div className={styles.cardContainer}>
        <IndividualCard text={cardInfo.ocd} icon={signs} title="OCD and Anxiety" />
        <IndividualCard text={cardInfo.adolescents} icon={heart} title="Adolescents" />
      </div>

      <div className={styles.cardContainer}>
        <IndividualCard text={cardInfo.scrupulosity} icon={child} title="Scrupulosity" />
        <IndividualCard text={cardInfo.trauma} icon={brain} title="Trauma" />
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>
    </section>
  );
};

export default Cards;
