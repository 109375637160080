import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './individualCard.module.scss';

const IndividualCard = ({ title, icon, text, imageUrl }) => {
  let link;
  const altText = '';

  switch (title) {
    case 'OCD and Anxiety': {
      link = '/specialties/obsessive-compulsive-disorder';
      break;
    }
    case 'Adolescents': {
      link = '/relationships/adolescents';
      break;
    }
    case 'Scrupulosity': {
      link = '/specialties/scrupulosity-ocd';
      break;
    }
    case 'Trauma': {
      link = '/specialties/trauma';
      break;
    }
    default: {
      break;
    }
  }

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardIcon}>{icon}</div>
      <div className={styles.cardBody}>
        <h2 className={styles.title}>{title}</h2>
        <summary>
          <p>{text}</p>
        </summary>

        {imageUrl && (
          <div className={styles.imageContainer}>
            <Link to={link}>
              <img src={imageUrl} alt={altText} />
            </Link>
          </div>
        )}

        {title === 'Imperfectly Good' && (
          <div className={styles.actionButtonContainer}>
            <a
              href="https://www.amazon.com/Imperfectly-Good-Navigating-Religious-Anxiety-ebook/dp/B0BW7V3749/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.actionButton} role="presentation">
                BUY NOW ON AMAZON
              </div>
            </a>
          </div>
        )}

        <div className={styles.readMore}>
          <Link to={link}>Read more</Link>
        </div>
      </div>
    </div>
  );
};

IndividualCard.defaultProps = {
  imageUrl: null,
};

IndividualCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  imageUrl: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default IndividualCard;
