const homeTestimonials = [
  "I'm very satisfied with the treatment I received. They dealt with me very patiently and always made me feel hopeful for my future regarding my fears and anxieties. I left with tools to lead an emotionally happy, healthy rest of my life.",

  'From our very first visit with Annabella my daughter and I knew that we had found someone with a very clear understanding of anxiety and OCD. The tools that she taught my daughter and the exposure therapy have made a world of difference in her life. She is able to function better and she feels like she can move forward and feel happy again!',

  'I have learned so many wonderful things from my therapy with Annabella! One of the biggest things was I can’t fight the OCD thought, I just need to let it be there. Learning to have mindfulness has really helped me. I also am so amazed at how well writing and listening to scripts helps. And even though it is hard it is so worth it. Now whenever OCD strikes again, I will know exactly what to do!',

  'Thank you so very much for all your advice! You saved my relationship with my daughter! You taught me how to get less stress and how to handle stress when I get it. I will always remember your advice!',

  'I’m very grateful that we found Annabella Hagen. We had tried other counselors, but results were limited. Annabella’s specialty in OCD and anxiety made all the difference for my child. In addition to being knowledgeable and skilled at her craft, she is very kind, caring, and non-judgmental. She was a great fit for us.',
];

export default homeTestimonials;
