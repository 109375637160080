import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Home from '../components/Home';

export default () => (
  <>
    <SEO title="Anxiety & OCD Treatment in Provo, Utah | Cognitive Behavioral Therapy" />
    <Layout>
      <Home />
    </Layout>
  </>
);
