import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Dotdotdot from 'react-dotdotdot';

import styles from './post.module.scss';

const Post = ({ title, image, backupImage, slug, excerpt }) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const postImageStyle = {
    background: `url(${backupImage}) no-repeat center center`,
    backgroundSize: 'cover',
    height: 300,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  return (
    <Link
      className={styles.postContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      to={`/blog/${slug}`}
    >
      {image && (
        <div className={styles.postImageContainer}>
          <Img fluid={image} alt={title} />
        </div>
      )}

      {!image && backupImage && <div style={postImageStyle} />}

      <Dotdotdot className={styles.title} clamp={2} useNativeClamp>
        <p dangerouslySetInnerHTML={{ __html: title }} />
      </Dotdotdot>

      <Dotdotdot className={styles.excerpt} clamp={10} useNativeClamp>
        <summary dangerouslySetInnerHTML={{ __html: excerpt }} />
      </Dotdotdot>

      {hover && <div className={styles.postHover}>READ MORE</div>}
    </Link>
  );
};

Post.defaultProps = {
  image: null,
};

Post.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  backupImage: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
};

export default Post;
